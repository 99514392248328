<!--
  PACKAGE_NAME : src\pages\esp\auth\modal-auth-code.vue
  FILE_NAME : modal-auth-code
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 메뉴 컴포넌트 권한 추가 팝업
-->
<template>
  <div>
    <DxButton text="코드 추가" type="button" class="btn_XS default filled add1 mar_b15" :height="30" @click="addCode" />
    <DxButton text="선택코드 일괄삭제" type="button" class="btn_XS white light_filled trash mar_b15" :height="30" @click="removeAllCode" />
    <DxDataGrid
      ref="authCodeList"
      :data-source="dataSource"
      :show-borders="false"
      :show-column-headers="true"
      :show-column-lines="false"
      :show-row-lines="true"
      :row-alternation-enabled="false"
      :allow-column-reordering="true"
      height="300px"
      no-data-text="권한 코드가 없습니다."
      :onRowInserted="onRowInserted"
    >
      <DxEditing :allow-updating="true" mode="cell">
        <DxTexts confirmDeleteMessage="해당 권한 조건을 삭제하시겠습니까?" />
      </DxEditing>
      <DxSelectionGrid mode="multiple" show-check-boxes-mode="always" />
      <DxColumn data-field="id" sort-order="desc" :visible="false" />
      <DxColumn caption="명칭" data-field="name" alignment="center" :width="120" />
      <DxColumn caption="권한 코드" data-field="key" alignment="center" edit-cell-template="autoCompleteTemplate" :width="200" />
      <DxColumn caption="일괄등록" alignment="center" cell-template="bulkTemplate" />
      <DxColumn caption="권한 여부" alignment="center" cell-template="toggleTemplate" :width="100" />
      <DxColumn caption="삭제" alignment="center" cell-template="removeTemplate" :width="80" />
      <template #autoCompleteTemplate="{ data }">
        <div>
          <DxAutocomplete
            :items="permissionList"
            :value="data.data.key"
            value-expr="funcCode"
            maxLength="20"
            @focus-out="onValueChanged($event, data)"
            @item-click="onAutoCompleteValueChanged($event, data)"
            @key-down="onValueChanged($event, data)"
          />
        </div>
      </template>
      <template #bulkTemplate="{ data }">
        <div>
          <DxDropDownBox
            ref="dropDown"
            v-model="selectedBulkMenuKeys[data.data.id]"
            :items="bulkMenuList[data.data.id]"
            display-expr="menuNm"
            value-expr="id"
            placeholder="메뉴 선택"
            :defer-rendering="false"
            :show-clear-button="true"
            :styling-mode="'outlined'"
            :width="300"
            :height="30"
            class="mar_ri10"
            @value-changed="onChangedDropDownBox($event, data)"
            @closed="onClosedDropDownBox"
          >
            <template #content="{}">
              <DxTreeView
                :ref="`dropDownTreeView${data.data.id}`"
                v-model="selectedBulkMenuKeys[data.data.id]"
                :items="bulkMenuList[data.data.id]"
                data-structure="plain"
                :expand-all-enabled="true"
                display-expr="menuNm"
                :select-by-click="true"
                showCheckBoxesMode="selectAll"
                @item-selection-changed="selectionChanged(data)"
              >
                <DxSelectionGrid mode="multiple" show-check-boxes-mode="always" />
              </DxTreeView>
            </template>
          </DxDropDownBox>
        </div>
      </template>
      <template #toggleTemplate="{ data }">
        <div>
          <DxSwitch :width="30" style="margin-left: 20px" :value="data.data.value" @value-changed="data.data.value = $event.value" />
        </div>
      </template>
      <template #removeTemplate="{ data }">
        <div>
          <DxButton
            text=""
            template="<span class='mdi mdi-trash-can text-2xl'></span>"
            style="font-size: 20px"
            @click="onDeleteData(data)"
          />
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
  import DxDropDownBox from 'devextreme-vue/drop-down-box';

  import { DxButton } from 'devextreme-vue/button';
  import { DxDataGrid, DxEditing, DxTexts, DxSelection as DxSelectionGrid } from 'devextreme-vue/data-grid';
  import { DxColumn } from 'devextreme-vue/tree-list';
  import DxTreeView from 'devextreme-vue/tree-view';
  import DxSwitch from 'devextreme-vue/switch';
  import { DxAutocomplete } from 'devextreme-vue/autocomplete';
  import { cloneObj } from '@/plugins/common-lib';

  export default {
    components: {
      DxTreeView,
      DxDropDownBox,
      DxColumn,
      DxDataGrid,
      DxEditing,
      DxTexts,
      DxButton,
      DxSelectionGrid,
      DxSwitch,
      DxAutocomplete,
    },
    props: {
      modalData: Object,
    },
    data() {
      return {
        dataSource: [],
        bulkMenuList: {},
        selectedBulkMenuKeys: {},
        globalRemoveCodes: [],
      };
    },
    computed: {
      permissionList() {
        return this.modalData.permissionList;
      },
    },
    methods: {
      /**
       * 컴포넌트가 파괴되기 전에 이벤트 리스너를 해제
       */
      beforeDestroy() {
        this.$_eventbus.$off('ModalAuthCode:onSaveData');
      },
      /**
       * 코드 추가
       */
      addCode() {
        this.$refs.authCodeList?.instance.addRow().then(() => {
          // instance 미등록 시 저장되지 않음.
          this.$refs.authCodeList?.instance.saveEditData();
        });
      },
      /**
       * 행이 삽입될 때 호출 이벤트
       * @param {Object} e - 이벤트 객체
       */
      onRowInserted(e) {
        e.data.id = e.data.id === undefined ? this.dataSource.length + 1 : e.data.id;
        this.bulkMenuList[e.data.id] = cloneObj(this.modalData.menuList);
      },
      /**
       * 데이터 항목을 삭제하는 메서드
       * @param {Object} data - 데이터 객체
       */
      onDeleteData(data) {
        this.dataSource = this.dataSource.filter(item => item.id !== data.data.id);
      },
      /**
       * 선택한 모든 코드를 제거
       */
      async removeAllCode() {
        const targetCodes = this.$refs.authCodeList.instance?.getSelectedRowKeys();
        if (targetCodes.length === 0) {
          this.$_Toast('삭제할 권한 코드를 선택해주세요.');
          return;
        }

        if (await this.$_Confirm('선택한 권한 코드를 전체 공통권한에서 일괄 삭제하시겠습니까?')) {
          this.globalRemoveCodes = targetCodes;
          for (const code of targetCodes) {
            this.dataSource.splice(
              this.dataSource.findIndex(item => item.key === code.key),
              1,
            );
          }
        }
      },
      /**
       * 드롭다운 값이 변경될 때 호출되는 메서드
       * @param {Event} e - 이벤트 객체
       * @param {Object} data - 데이터 객체
       */
      onChangedDropDownBox(e, data) {
        if (!e.value) {
          this.selectedBulkMenuKeys[data.data.id] = [];
          this.$refs[`dropDownTreeView${data.data.id}`]?.instance.unselectAll();
          this.$refs.authCodeList?.instance.saveEditData();
        }
      },
      /**
       * 드롭다운이 닫힐 때 호출되는 메서드
       */
      onClosedDropDownBox() {
        this.$refs.authCodeList?.instance.saveEditData();
      },
      /**
       * 항목 선택이 변경될 때 호출되는 메서드
       * @param {Object} data - 데이터 객체
       */
      selectionChanged(data) {
        if (data.data.id === undefined) return;
        this.selectedBulkMenuKeys[data.data.id] = this.$refs[`dropDownTreeView${data.data.id}`]?.instance.getSelectedNodeKeys();
      },
      /**
       * 권한 코드 변경 처리 이벤트
       * @param {Event} e - 이벤트 객체
       * @param {Object} data - 데이터 객체
       */
      onValueChanged(e, data) {
        if (e.event.type === 'keydown' && e.event.key !== 'Enter') return;
        data.data.key = e.component._changedValue !== undefined ? e.component._changedValue : data.data.key;
      },
      /**
       * 권한 코드 자동 완성 선택이 변경될 때 호출되는 메서드
       * @param {Event} e - 이벤트 객체
       * @param {Object} data - 데이터 객체
       */
      onAutoCompleteValueChanged(e, data) {
        data.data.name = e.itemData.funcName;
        this.$refs.authCodeList?.instance.refresh();
      },
    },
    async created() {
      if (!this.modalData.permissions) return;
      let i = 1;
      for (const code of Object.keys(this.modalData.permissions)) {
        this.bulkMenuList[i] = cloneObj(this.modalData.menuList);
        this.dataSource.push({
          id: i++,
          name: this.modalData.permissions[code].name,
          key: code,
          value: this.modalData.permissions[code].value,
        });
      }
    },
    mounted() {
      const vm = this;
      this.$_eventbus.$on('ModalAuthCode:onSaveData', async (e, resolve) => {
        vm.$refs.authCodeList?.instance.saveEditData();

        for (const item of vm.dataSource) {
          if (item.name === undefined || item.name === '') {
            vm.$_Toast('명칭을 입력해주세요.');
            return;
          }
          if (item.key === null || item.key === undefined || item.key === '') {
            vm.$_Toast('권한 코드를 입력해주세요.');
            return;
          }

          item.name = item.name.trim();
          item.key = item.key.trim();
        }

        let data = {
          id: vm.modalData.id,
          authCode: vm.dataSource,
          bulkMenuList: vm.selectedBulkMenuKeys,
          globalRemoveCodes: vm.globalRemoveCodes,
          res: 200,
        };

        resolve(data);
        vm.beforeDestroy();
      });
    },
    beforeMount() {
      this.beforeDestroy();
    },
    beforeDestroyed() {
      this.beforeDestroy();
    },
  };
</script>

<style scoped></style>
